import React, { useState } from 'react';
import {
  Box,
  Paper,
  TextField,
  Typography,
  AppBar,
  Toolbar,
  Button,
  Stack,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from '@mui/material';
import { theme } from '../../App';
import { CodeiumEditor } from '@codeium/react-code-editor';
import { getInterview, sendCodingAssignment } from '../../api/ai.service';
import { useMutation, useQuery } from '@tanstack/react-query';
import { useNavigate, useParams } from 'react-router';

export const InterviewCoding = () => {
  const params = useParams();
  const navigate = useNavigate();
  const interviewId = params.id;
  const [dialogOpen, setDialogOpen] = useState(false);

  const [code, setCode] = useState(`// Start coding...
    function greet(name) {
      return \`Hello, \${name}!\`;
    }
    
    console.log(greet("World"));`);

  const { data, isLoading } = useQuery({
    queryKey: [getInterview.name, interviewId],
    queryFn: () => getInterview(interviewId),
    enabled: !!interviewId,
  });

  const sendCodingAssignmentMutation = useMutation({ mutationFn: sendCodingAssignment });

  const handleDialogOpen = () => {
    setDialogOpen(true);
  };

  const handleSubmit = () => {
    // For demonstration, we just log the code.
    // In a real app, you could send this to a backend or save it locally.
    console.log('Saved code:', code);
    sendCodingAssignmentMutation.mutate(
      {
        id: +interviewId,
        body: { code },
      },
      {
        onSuccess: () => {
          setDialogOpen(false);
          navigate(`/interview/${interviewId}/finish`);
        },
      }
    );
  };

  return (
    <>
      <Box
        sx={{
          height: '100vh',
          display: 'flex',
          flexDirection: 'row',
          backgroundColor: theme.palette.secondary.main,
          padding: 2,
          gap: 2,
        }}
      >
        <Box
          sx={{
            width: '40%',
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            backgroundColor: 'background.paper',
            borderRadius: 2,
            overflow: 'hidden',
          }}
        >
          {/* Chat Header */}
          <AppBar position="static" sx={{ backgroundColor: 'background.paper' }}>
            <Toolbar>
              <Typography variant="h6">Coding excercise</Typography>
            </Toolbar>
          </AppBar>

          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              flex: 1,
              p: 2,
              overflowY: 'auto',
              backgroundColor: 'background.paper',
              gap: 1.5,
            }}
          >
            <Box>
              <Typography variant="subtitle2" sx={{ fontWeight: 'bold', mb: 0.5 }}>
                Description
              </Typography>
              <Typography variant="body2">{data?.coding_assignment?.description}</Typography>
            </Box>
            <Box>
              <Typography variant="subtitle2" sx={{ fontWeight: 'bold', mb: 0.5 }}>
                Requirements
              </Typography>

              <ul>
                {data?.coding_assignment?.requirements.map((requirement, index) => (
                  <li key={index}>
                    <Typography variant="body2">{requirement}</Typography>
                  </li>
                ))}
              </ul>
            </Box>
            <Box>
              <Typography variant="subtitle2" sx={{ fontWeight: 'bold', mb: 0.5 }}>
                Examples
              </Typography>

              <ul>
                {data?.coding_assignment?.examples.map((example, index) => (
                  <li key={index}>
                    <Typography sx={{ fontSize: '12px' }}>
                      <code>{example}</code>
                    </Typography>
                  </li>
                ))}
              </ul>
            </Box>
          </Box>
        </Box>

        <Box
          sx={{
            width: '60%',
            height: '100%',
            backgroundColor: theme.palette.secondary.light,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            borderRadius: 2,
            position: 'relative',
          }}
        >
          <CodeiumEditor
            language="python"
            theme="vs-dark"
            // containerStyle={{ height: '100%', width: '100%' }}
            height={'100%'}
            onChange={(value) => setCode(value)}
            value={code}
          />
          <Button
            variant="contained"
            size="large"
            onClick={handleDialogOpen}
            sx={{ position: 'absolute', bottom: '20px', right: '40px' }}
          >
            Submit
          </Button>
        </Box>
      </Box>
      <Dialog
        open={dialogOpen}
        onClose={() => setDialogOpen(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Submit coding exercise</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to submit the coding exercise?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDialogOpen(false)}>Go back</Button>
          <Button
            onClick={handleSubmit}
            autoFocus
            variant="contained"
            disabled={sendCodingAssignmentMutation.isLoading}
          >
            Submit & finish interview
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
