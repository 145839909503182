import React, { useEffect, useState } from 'react';
import { Box, Button, Chip, TextField, Typography } from '@mui/material';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useMutation, useQuery } from '@tanstack/react-query';
import { createInterview, getInterview } from '../../api/ai.service';
import { theme } from '../../App';
import { useForm } from 'react-hook-form';

export const InterviewAuthorize = () => {
  const params = useParams();
  const navigate = useNavigate();
  const interviewId = params.id;
  const [interview, setInterview] = useState(null);
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm({
    defaultValues: {
      name: '',
      email: '',
    },
  });

  const createInterviewMutation = useMutation({ mutationFn: createInterview });

  const onSubmit = ({ name, email }) => {
    createInterviewMutation.mutate({
      abstract_interview: +interviewId,
      candidate: {
        name,
        email,
      },
    });
  };

  // if (isLoading) return <div>Loading...</div>;

  if (createInterviewMutation.isSuccess) {
    return (
      <Box
        display="flex"
        width="100%"
        minHeight="100%"
        justifyContent="center"
        alignItems="center"
        bgcolor={theme.palette.background.paper}
      >
        <Typography variant="h5" mb={2}>
          The invitation was sent to your email
        </Typography>
      </Box>
    );
  }

  return (
    <Box
      display="flex"
      width="100%"
      minHeight="100%"
      justifyContent="center"
      alignItems="center"
      bgcolor={theme.palette.background.paper}
    >
      <Box display="flex" flexDirection="column" width="600px" p={4} gap={2}>
        <Typography variant="h5" mb={2} textAlign="center">
          Authorize to your Interview session
        </Typography>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Box display="flex" flexDirection="column" gap={2}>
            <TextField
              {...register('name')}
              label="Enter your name"
              variant="outlined"
              fullWidth
              sx={{
                '& .MuiOutlinedInput-root': {
                  '& fieldset': {
                    borderColor: theme.palette.primary.main,
                  },
                  '&:hover fieldset': {
                    borderColor: theme.palette.primary.main,
                  },
                  '&.Mui-focused fieldset': {
                    borderColor: theme.palette.primary.main,
                  },
                },
              }}
            />
            <TextField
              {...register('email')}
              label="Enter email"
              variant="outlined"
              fullWidth
              sx={{
                '& .MuiOutlinedInput-root': {
                  '& fieldset': {
                    borderColor: theme.palette.primary.main,
                  },
                  '&:hover fieldset': {
                    borderColor: theme.palette.primary.main,
                  },
                  '&.Mui-focused fieldset': {
                    borderColor: theme.palette.primary.main,
                  },
                },
              }}
            />
            <Button
              variant="contained"
              color="primary"
              fullWidth
              type="submit"
              disabled={createInterviewMutation.isPending}
              sx={{
                '&.Mui-disabled': {
                  backgroundColor: 'gray',
                },
              }}
            >
              Submit
            </Button>
          </Box>
        </form>
      </Box>
    </Box>
  );
};
