// @ts-nocheck

import React, { useEffect, useRef, useState } from 'react';
import {
  Box,
  Paper,
  TextField,
  Typography,
  AppBar,
  Toolbar,
  Button,
  Stack,
  Dialog,
  DialogContent,
  DialogActions,
  DialogTitle,
} from '@mui/material';
import { theme } from '../../App';
import { useMutation, useQuery } from '@tanstack/react-query';
import { sendMessage, startChat } from '../../api/ai.service';
import { Styled } from './interview-room.styles';
import { Link, useNavigate, useParams } from 'react-router';
import { Duration } from 'luxon';
import SpeechRecognition, { useSpeechRecognition } from 'react-speech-recognition';
import { WebcamRecorder } from '../../components/webcam-recorder/web-cam.component';
import { v4 } from 'uuid';

export const InterviewRoom = () => {
  const navigate = useNavigate();
  const [message, setMessage] = useState('');
  const [messages, setMessages] = useState([]);
  const [seconds, setSeconds] = useState(1200);

  const [isCodingTurn, setIsCodingTurn] = useState(false);
  const [isDone, setIsDone] = useState(false);

  const inputRef = useRef(null);
  const chatBoxRef = useRef(null);

  const { transcript, finalTranscript, listening, resetTranscript } = useSpeechRecognition();

  const params = useParams();

  const triggerChatQuery = useQuery({
    queryKey: ['start'],
    enabled: !!params.id,
    queryFn: () => startChat(params.id),
  });

  useEffect(() => {
    if (triggerChatQuery.isError) {
      alert('The chat is already active please join to new one');
    }

    if (triggerChatQuery.data) {
      const newMessage = triggerChatQuery.data.messages[0].message;
      const sender = triggerChatQuery.data.messages[0].sender;

      setMessages((prevMessages) => [...prevMessages, { message: newMessage, sender }]);
    }
  }, [triggerChatQuery.data, triggerChatQuery.isError]);

  const askQuestionMutation = useMutation({
    mutationFn: (message) => sendMessage({ id: params.id, message }),
  });

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, [inputRef]);

  useEffect(() => {
    const interval = setInterval(() => {
      setSeconds((prevSeconds) => {
        if (prevSeconds <= 1) {
          clearInterval(interval);
          navigate('/coding-interview');
          return 0;
        }
        return prevSeconds - 1;
      });
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    setMessage(transcript);
  }, [transcript]);

  useEffect(() => {
    if (finalTranscript) {
      setTimeout(() => {
        handleSend();
      }, 2000);
    }
  }, [finalTranscript]);

  const scrollToBottom = () => {
    if (chatBoxRef.current) {
      chatBoxRef.current.scrollTo(0, chatBoxRef.current.scrollHeight, { behavior: 'smooth' });
    }
  };

  const handleSend = () => {
    setMessages((prevMessages) => [...prevMessages, { message, sender: 'candidate' }]);
    setMessage('');
    askQuestionMutation.mutate(message, {
      onSuccess: (data) => {
        const newMessage = data[0].message;
        const sender = data[0].sender;

        if (data[0].is_completed && !!triggerChatQuery.data?.coding_assignment) {
          setIsCodingTurn(true);
          SpeechRecognition.stopListening();
          return;
        }

        if (data[0].is_completed && !triggerChatQuery.data?.coding_assignment) {
          setIsDone(true);
          SpeechRecognition.stopListening();
          return;
        }

        setMessages((prevMessages) => [...prevMessages, { message: newMessage, sender }]);
        inputRef.current?.focus();
        resetTranscript();
        scrollToBottom();
      },
      onError: () => {
        setMessages((prevMessages) => [...prevMessages, 'Could not get response']);
        inputRef.current?.focus();
        resetTranscript();
        scrollToBottom();
      },
    });
  };

  const handleChange = (e) => {
    setMessage(e.target.value);
  };

  return (
    <>
      <Box
        sx={{
          height: '100%',
          display: 'flex',
          flexDirection: 'row',
          backgroundColor: theme.palette.secondary.main,
          padding: 2,
          gap: 2,
        }}
      >
        <Box
          sx={{
            width: '60%',
            height: '100%',
            backgroundColor: theme.palette.secondary.light,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            borderRadius: 2,
            position: 'relative',
          }}
        >
          <Styled.Timer>{Duration.fromObject({ seconds }).toFormat('m:ss')}</Styled.Timer>
          <WebcamRecorder isPreview={false} isPending={askQuestionMutation.isPending} />
        </Box>

        {/* Right: Chat Section (40%) */}
        <Box
          sx={{
            width: '40%',
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            backgroundColor: 'background.paper',
            borderRadius: 2,
            overflow: 'hidden',
          }}
        >
          {/* Chat Header */}
          <AppBar position="static" sx={{ backgroundColor: 'background.paper' }}>
            <Toolbar width="100%" sx={{ borderBottom: '1px solid #ededed' }}>
              <Typography variant="h6" textAlign="center" width="100%">
                Interview Chat
              </Typography>
            </Toolbar>
          </AppBar>

          {/* Chat Messages Area */}
          <Box
            ref={chatBoxRef}
            sx={{
              flex: 1,
              p: 2,
              overflowY: 'scroll',
              backgroundColor: 'background.paper',
            }}
          >
            {triggerChatQuery.isLoading ? <Typography variant="body2">Generating...</Typography> : null}
            {messages.map((item, index) => {
              if (item.sender === 'assistant') {
                return (
                  <Paper
                    key={v4()}
                    sx={{
                      p: 2,
                      mb: 2,
                      maxWidth: '70%',
                      marginLeft: 'auto',
                      color: '#fff',
                      backgroundColor: theme.palette.primary.main,
                    }}
                  >
                    <Typography variant="subtitle2" sx={{ fontWeight: 'bold', mb: 0.5 }}>
                      RemoFirst AI Interviewer
                    </Typography>
                    <Typography variant="body2">{item.message}</Typography>
                  </Paper>
                );
              } else {
                return (
                  <Paper
                    key={v4()}
                    sx={{
                      p: 2,
                      mb: 2,
                      maxWidth: '70%',
                      backgroundColor: 'background.paper',
                    }}
                  >
                    <Typography variant="subtitle2" sx={{ fontWeight: 'bold', mb: 0.5 }}>
                      Candidate
                    </Typography>
                    <Typography variant="body2">{item.message}</Typography>
                  </Paper>
                );
              }
            })}
            {askQuestionMutation.isPending ? <Typography variant="body2">Generating...</Typography> : null}
          </Box>

          {/* Chat Input Area */}
          <Box sx={{ p: 2 }}>
            <Stack direction="row" spacing={1} alignItems="center">
              <TextField
                inputRef={inputRef}
                variant="outlined"
                placeholder={listening ? 'Listening...' : 'Type your message...'}
                fullWidth
                multiline
                onChange={handleChange}
                value={message}
              />
              <Button
                disableElevation
                disableRipple
                variant="outlined"
                color="primary"
                onClick={handleSend}
                disabled={askQuestionMutation.isPending}
              >
                Send
              </Button>
            </Stack>
          </Box>
        </Box>
      </Box>

      {isCodingTurn ? (
        <Dialog open>
          <DialogContent>You have successfully completed the interview, now move to coding phase.</DialogContent>
          <DialogActions>
            <Link to={`/interview/${params.id}/coding`} style={{ textDecoration: 'none' }}>
              <Button variant="outlined">OK</Button>
            </Link>
          </DialogActions>
        </Dialog>
      ) : null}

      {isDone ? (
        <Dialog open>
          <DialogTitle>You're all done there!</DialogTitle>
          <DialogContent>You have successfully completed the interview </DialogContent>
          <DialogActions>
            <Link to={`interview/${params.id}/report`} style={{ textDecoration: 'none' }}>
              <Button variant="outlined">Go to report</Button>
            </Link>
          </DialogActions>
        </Dialog>
      ) : null}
    </>
  );
};
