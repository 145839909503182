// @ts-nocheck

import { axiosInstance } from './axios-instance';

export const startChat = async (id) => {
  const { data } = await axiosInstance.put(`/interviews/${id}/start/`);
  return data;
};

export const sendMessage = async ({ id, message }) => {
  const { data } = await axiosInstance.post(`/interviews/${id}/messages/`, { message });
  return data;
};

export const getMessages = async (id) => {
  const { data } = await axiosInstance.get(`/interviews/${id}/messages`);

  return data;
};

export const askQuestion = async (body) => {
  const { data } = await axiosInstance.post('ai/interview-questions/', body);
  return data;
};

export const getAbstractInterviews = async () => {
  const { data } = await axiosInstance.get(`/abstract-interviews/`);
  return data;
};

export const getInterview = async (id) => {
  const { data } = await axiosInstance.get(`/interviews/${id}/`);
  return data;
};

export const createInterview = async (body) => {
  const { data } = await axiosInstance.post(`/interviews/`, body);
  return data;
};

export const sendCodingAssignment = async ({ id, body }) => {
  const { data } = await axiosInstance.post(`/interviews/${id}/coding_assignment/`, body);
  return data;
};
