import { Route, Routes } from 'react-router';
import './App.css';
import React from 'react';
import { InterviewRoom } from './features/interview-room/interview-room.component';

import { createTheme, ThemeProvider } from '@mui/material/styles';
import { CssBaseline } from '@mui/material';
import { InterviewCoding } from './features/interview-coding/interview-coding.component';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { InterviewReport } from './features/interview-report/interview-report.component';
import { InterviewJoin } from './features/interview-join/interview-join.component';
import { InterviewList } from './features/interview-list/interview-list.component';
import { InterviewAuthorize } from './features/interview-authorize/interview-authorize.component';
import { InterviewFinish } from './features/interview-finish/interview-finish.component';

export const theme = createTheme({
  palette: {
    mode: 'dark',
    primary: {
      main: '#40b84c',
    },
    secondary: {
      main: '#1f2120',
    },
    background: {
      default: '#f5f5f5',
      paper: '#ffffff',
    },
    text: {
      primary: '#333333',
      secondary: '#555555',
    },
  },
});

function App() {
  const queryClient = new QueryClient({ defaultOptions: { queries: { refetchOnWindowFocus: false, retry: false } } });

  return (
    <QueryClientProvider client={queryClient}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Routes>
          <Route index element={<InterviewList />} />
          <Route path="/interview/:id/authorize" element={<InterviewAuthorize />} />
          <Route path="/interview/:id" element={<InterviewJoin />} />
          <Route path="/interview/:id/theory" element={<InterviewRoom />} />
          <Route path="/interview/:id/coding" element={<InterviewCoding />} />
          <Route path="/interview/:id/report" element={<InterviewReport />} />
          <Route path="/interview/:id/finish" element={<InterviewFinish />} />
        </Routes>
      </ThemeProvider>
    </QueryClientProvider>
  );
}

export default App;
