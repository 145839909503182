import { Box, Chip, Typography } from '@mui/material';
import { theme } from '../../App';
import { useQuery } from '@tanstack/react-query';
import { getAbstractInterviews } from '../../api/ai.service';
import { Link } from 'react-router';

export const InterviewList = () => {
  const { data, isLoading } = useQuery({
    queryKey: [getAbstractInterviews.name],
    queryFn: () => getAbstractInterviews(),
  });

  console.log(data);

  if (isLoading) return <div>Loading...</div>;

  return (
    <Box
      display="flex"
      width="100%"
      minHeight="100%"
      bgcolor={theme.palette.background.default}
      justifyContent="center"
    >
      <Box display="flex" flexDirection="column" width="1000px" bgcolor={theme.palette.background.paper} p={4} gap={4}>
        <Typography variant="h5" mb={2} textAlign="center">
          Remohire AI
        </Typography>
        <Box>
          <Typography variant="h6" mb={2}>
            Interviews
          </Typography>

          <Box display="flex" gap={2} flexWrap="wrap">
            {data.results.map((item) => (
              <Box
                width="300px"
                bgcolor={theme.palette.background.paper}
                p={2}
                sx={{ border: '1px solid #ededed', borderRadius: 2 }}
                gap={2}
                display="flex"
                flexDirection="column"
              >
                <Typography variant="body1">
                  <Link to={`/interview/${item.id}/authorize/`}>{item.position.title}</Link>
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  {item.position.description}
                </Typography>
                <Box display="flex" gap={1} mb={4} flexWrap="wrap">
                  {item.position.skills.map((skill) => (
                    <Chip key={skill} label={skill} color="primary" />
                  ))}
                </Box>
              </Box>
            ))}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
