import { styled } from '@mui/material';

const Timer = styled('div')(({ theme }) => ({
  position: 'absolute',
  top: '10px',
  right: '10px',
  fontSize: '24px',
  fontWeight: 'bold',
  color: theme.palette.primary.main,
  zIndex: 1,
  borderRadius: 2,
  backgroundColor: '#fff',
  padding: '5px 10px',
}));

export const Styled = {
  Timer,
};
