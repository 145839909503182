//@ts-nocheck

import { Box, Button, Typography } from '@mui/material';
import { theme } from '../../App';
import { Link, useParams } from 'react-router';

export const InterviewFinish = () => {
  const params = useParams();

  return (
    <Box
      display="flex"
      width="100%"
      minHeight="100%"
      justifyContent="center"
      alignItems="center"
      flexDirection="column"
      bgcolor={theme.palette.background.paper}
    >
      <Typography variant="h5" mb={2}>
        You have successfully completed the AI interview.
      </Typography>

      <Link to={`/interview/${params.id}/report`}>
        <Button variant='outlined'>Close</Button>
      </Link>
    </Box>
  );
};
