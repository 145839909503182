import React, { useEffect, useState } from 'react';
import { Box, Button, Chip, Typography } from '@mui/material';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useMutation, useQuery } from '@tanstack/react-query';
import { getInterview } from '../../api/ai.service';
import { WebcamRecorder } from '../../components/webcam-recorder/web-cam.component';
import { theme } from '../../App';

export const InterviewJoin = () => {
  const params = useParams();
  const navigate = useNavigate();
  const interviewId = params.id;
  const [interview, setInterview] = useState(null);
  const [stream, setStream] = useState(null);

  // const askQuestionMutation = useMutation({ mutationFn: askQuestion });

  const { data, isLoading } = useQuery({
    queryKey: [getInterview.name, interviewId],
    queryFn: () => getInterview(interviewId),
    enabled: !!interviewId,
  });

  const handleShareScreen = async () => {
    try {
      const mediaStream = await navigator.mediaDevices.getDisplayMedia({ video: true });
      setStream(mediaStream);
      console.log('Screen sharing started:', mediaStream);

      // Listen for the screen sharing stopping event
      const videoTrack = mediaStream.getVideoTracks()[0];
      videoTrack.onended = () => {
        setStream(null);
        console.log('Screen sharing stopped');
      };
    } catch (error) {
      console.error('Error sharing screen:', error);
    }
  };

  const handleStart = () => {
    navigate(`/interview/${interviewId}/theory`);
  };

  if (isLoading) return <div>Loading...</div>;

  return (
    <Box
      display="flex"
      width="100%"
      minHeight="100%"
      justifyContent="center"
      alignItems="center"
      bgcolor={theme.palette.background.paper}
    >
      <Box display="flex" flexDirection="column" width="900px" p={4} gap={2}>
        <Typography variant="h5">{data.position.title}</Typography>
        <Typography variant="body1" mb={2}>
          {data.position.description}
        </Typography>
        <Box display="flex" gap={1} mb={4}>
          {data.position.skills.map((skill) => (
            <Chip key={skill} label={skill} color="primary" />
          ))}
        </Box>
        <Box>
          Before beginning the interview, please keep in mind:
          <ol>
            <li>
              Your AI interview will be recorded and accessible from your profile. Remain within the interview
              environment throughout.
            </li>
            <li>Avoid navigating to other browser tabs.</li>
            <li>Maintain eye contact with the screen and minimize looking away.</li>
            <li>Do not hesitate to ask clarifying questions at any point during the interview.</li>
          </ol>
        </Box>
        {!stream && (
          <Box>
            Please share your screen to start the interview{' '}
            <Button variant="contained" onClick={handleShareScreen}>
              Share
            </Button>
          </Box>
        )}
        <Box display="flex" alignItems="center" gap={2}>
          <Box>
            <WebcamRecorder isPreview />
          </Box>

          <Box display="flex" flexDirection="column" gap={2} alignItems="center">
            <Typography>Ready to join?</Typography>
            <Button
              variant="contained"
              onClick={handleStart}
              disabled={!stream}
              sx={{
                '&.Mui-disabled': {
                  backgroundColor: 'gray',
                },
              }}
            >
              Start Interview
            </Button>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
