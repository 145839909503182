// @ts-nocheck
import { Box, Button, IconButton } from '@mui/material';
import React, { useEffect } from 'react';
import Webcam from 'react-webcam';
import { PiPhoneDisconnect } from 'react-icons/pi';
import SpeechRecognition, { useSpeechRecognition } from 'react-speech-recognition';

import { LiaMicrophoneSlashSolid } from 'react-icons/lia';
import { LiaMicrophoneSolid } from 'react-icons/lia';
import { Link, useNavigate } from 'react-router';

export const WebcamRecorder = ({ isPreview, isPending }) => {
  const webcamRef = React.useRef(null);
  const mediaRecorderRef = React.useRef(null);
  const [capturing, setCapturing] = React.useState(false);
  const [recordedChunks, setRecordedChunks] = React.useState([]);
  let navigate = useNavigate();

  const { listening } = useSpeechRecognition();

  const handleDataAvailable = React.useCallback(
    ({ data }) => {
      if (data.size > 0) {
        setRecordedChunks((prev) => prev.concat(data));
      }
    },
    [setRecordedChunks]
  );

  const handleStart = React.useCallback(() => {
    setCapturing(true);

    if (mediaRecorderRef.current) {
      mediaRecorderRef.current = new MediaRecorder(webcamRef.current.stream, {
        mimeType: 'video/webm',
      });
      mediaRecorderRef.current.addEventListener('dataavailable', handleDataAvailable);
      mediaRecorderRef.current.start();
    }
  }, [webcamRef, setCapturing, mediaRecorderRef, handleDataAvailable]);

  const handleStop = React.useCallback(() => {
    if (mediaRecorderRef.current) {
      mediaRecorderRef.current.stop();
      setCapturing(false);
    }
  }, [mediaRecorderRef, setCapturing]);

  useEffect(() => {
    if (isPreview) {
      handleStart();

      return () => {
        handleStop();
      };
    }
  }, [handleStart, handleStop]);

  const handleDownload = React.useCallback(() => {
    if (recordedChunks.length) {
      const blob = new Blob(recordedChunks, {
        type: 'video/webm',
      });
      const url = URL.createObjectURL(blob);
      const a = document.createElement('a');
      document.body.appendChild(a);
      a.style = 'display: none';
      a.href = url;
      a.download = 'react-webcam-stream-capture.webm';
      a.click();
      window.URL.revokeObjectURL(url);
      setRecordedChunks([]);
    }
  }, [recordedChunks]);

  return (
    <Box
      display="flex"
      flexDirection="column"
      gap={2}
      width="100%"
      height="100%"
      alignItems="center"
      justifyContent="center"
      sx={{
        backgroundColor: '#000',
      }}
    >
      <Webcam width="100%" ref={webcamRef} audio muted className="webcam" disablePictureInPicture></Webcam>

      {!isPreview ? (
        <Box padding={2} borderRadius={2} display="flex" gap={3} sx={{ background: '#fff' }}>
          <IconButton color="error" size="large" onClick={() => {
            // eslint-disable-next-line no-restricted-globals
            const isOK = confirm('Are you sure you want to exit the interview? If you leave now, you will lose your progress.');
            if (isOK) {
              navigate('/');
            }
          }}>
            <PiPhoneDisconnect size={30} />
          </IconButton>

          <Box display="flex" alignItems="center" gap={2}>
            <IconButton
              size="large"
              color={listening ? 'success' : 'error'}
              onClick={() => {
                if (listening) {
                  SpeechRecognition.stopListening();
                } else {
                  SpeechRecognition.startListening({ continuous: true });
                }
              }}
            >
              {listening ? <LiaMicrophoneSolid /> : <LiaMicrophoneSlashSolid />}
            </IconButton>
          </Box>
        </Box>
      ) : null}
    </Box>
  );
};
