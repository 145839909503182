// @ts-nocheck
import { Box, Card, CardContent, Divider, Typography } from '@mui/material';
import { theme } from '../../App';
import { PieChart, Pie, Cell } from 'recharts';
import { useQuery } from '@tanstack/react-query';
import { getInterview, getMessages } from '../../api/ai.service';
import { useParams } from 'react-router';

const squashed = (arr) => {
  return arr.map((item, index, self) => ({
    question: item.message,
    answer: self[index + 1]?.message,
  }));
};

export const InterviewReport = () => {
  const progress = 85;
  const validProgress = Math.max(0, Math.min(100, progress));

  const params = useParams();

  const messagesQuery = useQuery({
    enabled: !!params.id,
    queryKey: ['messages'],
    queryFn: () => getMessages(params.id),
  });

  const interviewQuery = useQuery({
    enabled: !!params.id,
    queryKey: ['interview'],
    queryFn: () => getInterview(params.id),
  });

  const data = [
    { name: 'Filled', value: validProgress },
    { name: 'Empty', value: 100 - validProgress },
  ];

  const COLORS = [theme.palette.primary.main, '#e0e0e0'];

  if (messagesQuery.isLoading || interviewQuery.isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <Box
      display="flex"
      width="100%"
      minHeight="100%"
      bgcolor={theme.palette.background.default}
      justifyContent="center"
    >
      <Box
        display="flex"
        flexDirection="column"
        width="1000px"
        height="100%"
        bgcolor={theme.palette.background.paper}
        p={4}
        gap={4}
      >
        <Typography variant="h5" mb={2}>
          Interview Report for Candidate
        </Typography>

        {interviewQuery.data ? (
          <>
            <Box>{interviewQuery.data ? interviewQuery.data.qa_feedback : null}</Box>

            <Box>
              {interviewQuery.data.candidate?.skills?.map(({ skill, score }) => (
                <Box key={skill} display="flex" gap={2}>
                  <Typography>{skill}: </Typography>
                  <Typography>{Math.round(score)}</Typography>
                </Box>
              ))}
            </Box>
            <Box>
              <Typography>Total score: {interviewQuery.data.qa_total_score}</Typography>
            </Box>
          </>
        ) : null}

        {/* <Box display="flex" alignItems="center" justifyContent="space-between">
          <Box width="50%">
            <Typography variant="body1">Candidate Name</Typography>
            <Typography variant="body1">Candidate Email</Typography>
            <Typography variant="body1">Candidate Phone</Typography>
            <Typography variant="body1">Candidate Address</Typography>
          </Box>
          <Box>
            <Typography variant="h6" mb={1}>
              Overall high score
            </Typography>
            <PieChart width={150} height={150}>
              <Pie
                data={data}
                cx="50%"
                cy="50%"
                startAngle={90}
                endAngle={-270} // Draws clockwise
                innerRadius={50}
                outerRadius={70}
                dataKey="value"
                stroke="none"
              >
                {data.map((entry, index) => (
                  <Cell key={`cell-${index}`} fill={COLORS[index]} />
                ))}
              </Pie>
              <text x="50%" y="50%" textAnchor="middle" dominantBaseline="middle" fontSize={24}>
                {`${validProgress}%`}
              </text>
            </PieChart>
          </Box>
        </Box> */}
        {/* <Box>
          <Typography variant="h6" mb={1}>
            Overall Feedback
          </Typography>
          <Typography variant="body1" mb={1}>
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Illum id numquam dolore officiis voluptate quo
            distinctio sequi, pariatur labore explicabo error? Excepturi et suscipit, officia animi corrupti maiores
            reiciendis alias nemo at. Ratione, aspernatur commodi accusamus quidem debitis, corrupti, dolorem
            repellendus explicabo nihil totam facere? Perferendis dolores reprehenderit ex in!
          </Typography>
        </Box> */}
        {/* <Box display="flex" alignItems="center" justifyContent="space-around">
          <Box display="flex" flexDirection="column" alignItems="center" justifyContent="flex-start" width="33%">
            <Typography variant="h6" mb={1}>
              Python
            </Typography>
            <PieChart width={150} height={150}>
              <Pie
                data={data}
                cx="50%"
                cy="50%"
                startAngle={90}
                endAngle={-270} // Draws clockwise
                innerRadius={50}
                outerRadius={70}
                dataKey="value"
                stroke="none"
              >
                {data.map((entry, index) => (
                  <Cell key={`cell-${index}`} fill={COLORS[index]} />
                ))}
              </Pie>
              <text x="50%" y="50%" textAnchor="middle" dominantBaseline="middle" fontSize={24}>
                {`${validProgress}%`}
              </text>
            </PieChart>
          </Box>
          <Box display="flex" flexDirection="column" alignItems="center" justifyContent="flex-start" width="33%">
            <Typography variant="h6" mb={1}>
              Communcation
            </Typography>
            <PieChart width={150} height={150}>
              <Pie
                data={data}
                cx="50%"
                cy="50%"
                startAngle={90}
                endAngle={-270} // Draws clockwise
                innerRadius={50}
                outerRadius={70}
                dataKey="value"
                stroke="none"
              >
                {data.map((entry, index) => (
                  <Cell key={`cell-${index}`} fill={COLORS[index]} />
                ))}
              </Pie>
              <text x="50%" y="50%" textAnchor="middle" dominantBaseline="middle" fontSize={24}>
                {`${validProgress}%`}
              </text>
            </PieChart>
          </Box>
          <Box display="flex" flexDirection="column" alignItems="center" justifyContent="flex-start" width="33%">
            <Typography variant="h6" mb={1}>
              Problem solving
            </Typography>
            <PieChart width={150} height={150}>
              <Pie
                data={data}
                cx="50%"
                cy="50%"
                startAngle={90}
                endAngle={-270} // Draws clockwise
                innerRadius={50}
                outerRadius={70}
                dataKey="value"
                stroke="none"
              >
                {data.map((entry, index) => (
                  <Cell key={`cell-${index}`} fill={COLORS[index]} />
                ))}
              </Pie>
              <text x="50%" y="50%" textAnchor="middle" dominantBaseline="middle" fontSize={24}>
                {`${validProgress}%`}
              </text>
            </PieChart>
          </Box>
        </Box> */}
        {/* <Box>
          <Typography variant="h6" mb={1}>
            Question summary
          </Typography>
          <Typography variant="body1" mb={1}>
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Illum id numquam dolore officiis voluptate quo
            distinctio sequi, pariatur labore explicabo error? Excepturi et suscipit, officia animi corrupti maiores
            reiciendis alias nemo at. Ratione, aspernatur commodi accusamus quidem debitis, corrupti, dolorem
            repellendus explicabo nihil totam facere? Perferendis dolores reprehenderit ex in!
          </Typography>
        </Box> */}
        <Box width="500px" alignSelf="center">
          <Typography variant="h6" mb={1}>
            Interview Transcript
          </Typography>
          {/* <Box mb={1}>
            <video controls width="100%">
              <source
                src="https://00147cca-84e5-4145-8145-7f8752c5d0e6.mdnplay.dev/shared-assets/videos/flower.mp4"
                type="video/webm"
              />
              <source
                src="https://00147cca-84e5-4145-8145-7f8752c5d0e6.mdnplay.dev/shared-assets/videos/flower.webm"
                type="video/mp4"
              />
              Download the
              <a href="https://00147cca-84e5-4145-8145-7f8752c5d0e6.mdnplay.dev/shared-assets/videos/flower.mp4">
                WEBM
              </a>
              or
              <a href="https://00147cca-84e5-4145-8145-7f8752c5d0e6.mdnplay.dev/shared-assets/videos/flower.webm">
                MP4
              </a>
              video.
            </video>
          </Box> */}
          <Box display="flex" flexDirection="column-reverse">
            {messagesQuery.isSuccess &&
              messagesQuery.data.results.map(({ message, sender, feedback, feedback_score }, index) => {
                if (sender === 'assistant') {
                  return (
                    <Typography variant="body1" mb={3}>
                      <i>{message}</i>
                    </Typography>
                  );
                } else {
                  return (
                    <Box display="flex" flexDirection="column" gap={2} mb={3}>
                      <Typography variant="body1">
                        <strong>{message}</strong>
                      </Typography>
                      <Typography>
                        Feedback: <code>{feedback}</code>
                      </Typography>
                      <Typography>Score: {Math.round(feedback_score)}</Typography>
                      <hr width="100%" />
                    </Box>
                  );
                }
              })}
          </Box>
          {/* <Typography variant="body1" mb={1}>
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Illum id numquam dolore officiis voluptate quo
            distinctio sequi, pariatur labore explicabo error? Excepturi et suscipit, officia animi corrupti maiores
            reiciendis alias nemo at. Ratione, aspernatur commodi accusamus quidem debitis, corrupti, dolorem
            repellendus explicabo nihil totam facere? Perferendis dolores reprehenderit ex in!
          </Typography> */}
          <video width={500} controls>
            <source src={interviewQuery.data.record} type="video/webm" />
          </video>
        </Box>
      </Box>
    </Box>
  );
};
